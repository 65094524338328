class EventBus {
    bus: HTMLElement;

    constructor() {
        this.bus = document.createElement('eventbus');
    }

    addEventListener(event: any, callback: (this: HTMLElement, ev: any) => any) {
        this.bus.addEventListener(event, callback);
    }

    removeEventListener(event: any, callback: (this: HTMLElement, ev: any) => any) {
        this.bus.removeEventListener(event, callback);
    }

    dispatchEvent(event: string, detail = {}) {
        this.bus.dispatchEvent(new CustomEvent(event, { detail }));
    }
}

window.eventBus = new EventBus